import {
  Button,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { getProject } from '../../../common/services/project';
import { BookmakerSelect } from '../../bookmakers/components/BookmakerSelect';
import { CompetitionInput } from '../../competitions/CompetitionInput';
import MatchInput from '../../matches/Input';
import TeamInput from '../../teams/Input';
import { IComponentProps } from './AddMainBookmakerMapping.connector';

interface IState {
  competition: string | null;
  match: string | null;
  team: string | null;
  bookmaker: string | null;
}

export class AddMainBookmakerMapping extends React.Component<IComponentProps, IState> {
  public state: IState = {
    competition: null,
    match: null,
    team: null,
    bookmaker: null
  };

  private isFormValid = () => {
    const { bookmaker, match, team, competition } = this.state;

    // Bookmaker is always required
    if (!bookmaker) return false;

    // Match alone is valid
    if (match && !team && !competition) return true;

    // Team and competition together is valid
    if (team && competition && !match) return true;

    // Team alone is valid
    if (team && !competition && !match) return true;

    // Competition alone is valid
    return !!(competition && !team && !match);
  };

  public render() {
    const {
      intl: { messages: m }
    } = this.props;
    const { match, competition, team } = this.state;

    const isMatchMode = !!match;
    const isCompetitionOrTeamMode = !!competition || !!team;

    return (
      <Layout
        id="main-bookmaker-mapping-add-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_mainBookmakerMapping',
                href: `#${getProject().url}/main-bookmaker-mappings`,
              },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_mainBookmakerMappingAdd}</Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="main-bookmaker-mappings" />
            </Toolbar>
            <div style={{ padding: 16 }}>
              <div style={{ marginBottom: 16 }}>
                <BookmakerSelect
                  input={{
                    name: 'bookmaker',
                    onChange: this.handleBookmakerChange,
                    value: this.state.bookmaker
                  }}
                />
              </div>
              {!isMatchMode && (
                <>
                  <div style={{ marginBottom: 16 }}>
                    <CompetitionInput
                      input={{
                        name: 'competition',
                        onChange: this.handleCompetitionChange,
                        value: this.state.competition
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: 16 }}>
                    <TeamInput
                      input={{
                        name: 'team',
                        onChange: this.handleTeamChange,
                        value: this.state.team
                      }}
                    />
                  </div>
                </>
              )}
              {!isCompetitionOrTeamMode && (
                <div style={{ marginBottom: 16 }}>
                  <MatchInput
                    input={{
                      name: 'match',
                      onChange: this.handleMatchChange,
                      value: this.state.match
                    }}
                  />
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                disabled={!this.isFormValid()}
              >
                {m.button_validate}
              </Button>
            </div>
          </Paper>
        }
      />
    );
  }

  private handleBookmakerChange = (value: string | null) => {
    this.setState({ bookmaker: value });
  };

  private handleCompetitionChange = (value: string | null) => {
    this.setState({
      competition: value,
      match: null // Reset match when a competition is selected
      // Keep team value as it's compatible with competition
    });
  };

  private handleMatchChange = (value: string | null) => {
    this.setState({
      match: value,
      competition: null, // Reset both competition and team when a match is selected
      team: null
    });
  };

  private handleTeamChange = (value: string | null) => {
    this.setState({
      team: value,
      match: null // Reset match when a team is selected
      // Keep competition value as it's compatible with team
    });
  };

  private handleSubmit = () => {
    const { addMainBookmakerMapping, history } = this.props;
    const { competition, match, team, bookmaker } = this.state;

    // Double check validation before submitting
    if (!this.isFormValid()) return;

    const mapping = {
      bookmaker,
      ...(competition ? { competition } : {}),
      ...(match ? { match } : {}),
      ...(team ? { team } : {})
    };

    // @ts-ignore
    addMainBookmakerMapping(mapping).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/main-bookmaker-mappings/${payload.id}`);
      }
    });
  };
}
