import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { getProject } from '../../../common/services/project';
import { IComponentProps } from './MainBookmakerMappingList.connector';
import { MainBookmakerMappingRow } from './MainBookmakerMappingRow.component';
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";

export class MainBookmakerMappingList extends React.Component<IComponentProps> {
  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    const {
      mainBookmakerMappingsByMatch,
      mainBookmakerMappingsByTeamInCompetition,
      mainBookmakerMappingsByTeam,
      mainBookmakerMappingsByCompetition,
      fetchingMainBookmakerMappingsByMatch,
      fetchingMainBookmakerMappingsByTeamInCompetition,
      fetchingMainBookmakerMappingsByTeam,
      fetchingMainBookmakerMappingsByCompetition,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="main-bookmaker-mappings-layout"
        top={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <SimpleBreadcrumbs
              crumbStack={[{ labelKey: 'app_mainBookmakerMapping' }]}
            />
            <UserRestriction>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.props.history.push(`${getProject().url}/main-bookmaker-mappings/add`)}
              >
                <AddIcon style={{ marginRight: 8 }} />
                {this.props.intl.messages.button_add}
              </Button>
            </UserRestriction>
          </div>
        }
        main={[
          this.renderDocumentation(`#${getProject().url}/documentation/bookmakers`, m.read_the_docs),
          this.renderTable(
            mainBookmakerMappingsByMatch,
            fetchingMainBookmakerMappingsByMatch,
            m.table_main_bookmaker_mapping_match,
            'match',
            this.paginateMatchMappings
          ),
          this.renderTable(
            mainBookmakerMappingsByTeamInCompetition,
            fetchingMainBookmakerMappingsByTeamInCompetition,
            m.table_main_bookmaker_mapping_team_in_competition,
            'teamInCompetition',
            this.paginateTeamInCompetitionMappings
          ),
          this.renderTable(
            mainBookmakerMappingsByTeam,
            fetchingMainBookmakerMappingsByTeam,
            m.table_main_bookmaker_mapping_team,
            'team',
            this.paginateTeamMappings
          ),
          this.renderTable(
            mainBookmakerMappingsByCompetition,
            fetchingMainBookmakerMappingsByCompetition,
            m.table_main_bookmaker_mapping_competition,
            'competition',
            this.paginateCompetitionMappings
          ),
        ]}></Layout>
    );
  }

  private renderDocumentation = (href, message) => (
    <Paper elevation={2} style={{ marginBottom: 16 }}>
        <IconButton href={href} title={message} target="_blank">
          <LinkIcon />
          {message}
        </IconButton>
    </Paper>
  );

  private renderTable = (data, fetching, title, context, onPaginate) => (
    <Paper elevation={2} style={{ marginBottom: 16 }}>
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <TableList
        tableData={data}
        tableHeaders={this.getTableHeaders(context)}
        Row={(props) => <MainBookmakerMappingRow {...props} context={context} />}
        loading={fetching}
        pagination={this.props.pagination[context]}
        total={this.props.total[context]}
        onPaginate={onPaginate}
      />
    </Paper>
  );

  private getTableHeaders = (context) => {
    const headers = [{ labelKey: 'field_bookmaker' }];
    if (context === 'teamInCompetition' || context === 'team') headers.push({ labelKey: 'field_team' });
    if (context === 'teamInCompetition' || context === 'competition') headers.push({ labelKey: 'field_competition' });
    if (context === 'match') headers.push({ labelKey: 'field_match' });
    headers.push({ labelKey: 'field_createdAt' });
    return headers;
  };

  private fetchData = () => {
    const { fetchMappingsByMatch, fetchMappingsByTeamInCompetition, fetchMappingsByTeam, fetchMappingsByCompetition } = this.props;
    fetchMappingsByMatch();
    fetchMappingsByTeamInCompetition();
    fetchMappingsByTeam();
    fetchMappingsByCompetition();
  };

  private paginateMatchMappings = (query) => {
    this.props.fetchMappingsByMatch(query);
  };

  private paginateTeamInCompetitionMappings = (query) => {
    this.props.fetchMappingsByTeamInCompetition(query);
  };

  private paginateTeamMappings = (query) => {
    this.props.fetchMappingsByTeam(query);
  };

  private paginateCompetitionMappings = (query) => {
    this.props.fetchMappingsByCompetition(query);
  };
}
