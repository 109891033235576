import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from "query-string";

const groups = ['read'];

export function getBookmaker(id) {
  const params = {
    groups,
  };
  return function(dispatch) {
    dispatch({ type: 'GET_BOOKMAKER' });

    return rest
      .get(`bookmakers/${id}`, { params })
      .then(
        res => dispatch({ type: 'GET_BOOKMAKER_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_BOOKMAKER_REJECTED', payload: err.response })
      );
  };
}

export function fetchBookmakers(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BOOKMAKER_LIST' });

    return rest
      .get('bookmakers', { params })
      .then(
        res => dispatch({ type: 'FETCH_BOOKMAKER_LIST_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_BOOKMAKER_LIST_REJECTED', payload: err.response })
      );
  };
}

export function fetchAllBookmakers() {
  const params = {
    groups: ['read_link'],
    'order[priority]': 'asc',
    'pagination[enablePagination]': 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BOOKMAKER_LIST' });

    return rest
      .get('bookmakers', { params })
      .then(
        res => dispatch({ type: 'FETCH_BOOKMAKER_LIST_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_BOOKMAKER_LIST_REJECTED', payload: err.response })
      );
  };
}

export function updateBookmaker(id, bookmaker) {
  const params = {
    groups,
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_BOOKMAKER', payload: id });

    return rest.put(`bookmakers/${id}`, bookmaker, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_bookmakerUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_BOOKMAKER_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_BOOKMAKER_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}
