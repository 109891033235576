import MainLayout from 'Components/MainLayout';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import fetchMeOnce from '../common/actions/me';
import { getProjectNameFromPath, setProject } from '../common/services/project';
import { UsersRoutes } from '../common/users';
import { AgentCompaniesRoutes } from './agentCompanies';
import { BrandsRoutes } from './brands';
import BroadcastsRoutes from './broadcasts';
import ChannelsRoutes from './channels';
import ClubsRoutes from './clubs';
import CompetitionsRoutes from './competitions';
import Nav from './components/Navigation';
import Toolbar from './components/Toolbar';
import CorrectionsRoutes from './corrections';
import Dashboard from './dashboard';
import { DocumentationPages } from './documentation/DocumentationPages';
import ImagesRoutes from './images';
import InjuryTypesRoutes from './injuryTypes';
import { LiveRoutes } from './live';
import MatchesRoutes from './matches';
import MembershipsRoutes from './memberships';
import AgentsRoutes from './people/agents';
import CoachesRoutes from './people/coaches';
import PlayersRoutes from './people/players';
import PresidentsRoutes from './people/presidents';
import RefereesRoutes from './people/referees';
import StaffsRoutes from './people/staffs';
import { PhasesRoutes } from './phases/';
import { ProductsRoutes } from './products';
import Search from './search';
import StatsClubsRoutes from './stats/clubs';
import StatsPlayersRoutes from './stats/players';
import TeamsRoutes from './teams';
import TournamentsRoutes from './tournaments';
import TransfersRoutes from './transfers';
import { BookmakersRoutes } from './bookmakers';
import { SurveysRoutes } from 'FD/surveys';
import { QuizzesRoutes } from 'FD/quizz';
import { PartnersRoutes, PartnerOffersRoutes } from 'FD/partners';
import { LineupSurveysRoutes } from 'FD/lineupSurveys';
import { MercatoSurveysRoutes } from "FD/mercatoSurveys";
import { MainBookmakerMappingsRoutes } from "./mainBookmakerMappings";

@connect(store => ({
  me: store.me,
}))
export default class Footdata extends Component {
  UNSAFE_componentWillMount() {
    const { location, dispatch } = this.props;
    setProject(location.pathname);
    dispatch(fetchMeOnce());
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (getProjectNameFromPath(prevProps.location.pathname) !== getProjectNameFromPath(location.pathname)) {
      setProject(location.pathname);
    }
  }
  render() {
    const { match, location, me } = this.props;
    if (!me.fetched) return null;
    if (location.pathname.indexOf(`${match.url}/live`) === 0) {
      return <Route path={`${match.url}/live`} component={LiveRoutes} />;
    }

    return (
      <MainLayout toolbar={<Toolbar />} navigation={<Nav path={location.pathname} />}>
        <Switch>
          <Route exact path={match.url} component={Dashboard} />
          <Route path={`${match.url}/agent-companies`} component={AgentCompaniesRoutes} />
          <Route path={`${match.url}/agents`} component={AgentsRoutes} />
          <Route path={`${match.url}/broadcasts`} component={BroadcastsRoutes} />
          <Route path={`${match.url}/channels`} component={ChannelsRoutes} />
          <Route path={`${match.url}/clubs`} component={ClubsRoutes} />
          <Route path={`${match.url}/coaches`} component={CoachesRoutes} />
          <Route path={`${match.url}/competitions`} component={CompetitionsRoutes} />
          <Route path={`${match.url}/corrections`} component={CorrectionsRoutes} />
          <Route path={`${match.url}/images`} component={ImagesRoutes} />
          <Route path={`${match.url}/matches`} component={MatchesRoutes} />
          <Route path={`${match.url}/memberships`} component={MembershipsRoutes} />
          <Route path={`${match.url}/phases`} component={PhasesRoutes} />
          <Route path={`${match.url}/players`} component={PlayersRoutes} />
          <Route path={`${match.url}/presidents`} component={PresidentsRoutes} />
          <Route path={`${match.url}/referees`} component={RefereesRoutes} />
          <Route path={`${match.url}/search`} component={Search} />
          <Route path={`${match.url}/search-advanced`} component={Search} />
          <Route path={`${match.url}/staffs`} component={StaffsRoutes} />
          <Route path={`${match.url}/stats-clubs`} component={StatsClubsRoutes} />
          <Route path={`${match.url}/stats-players`} component={StatsPlayersRoutes} />
          <Route path={`${match.url}/teams`} component={TeamsRoutes} />
          <Route path={`${match.url}/tournaments`} component={TournamentsRoutes} />
          <Route path={`${match.url}/transfers`} component={TransfersRoutes} />
          <Route path={`${match.url}/injury-types`} component={InjuryTypesRoutes} />
          <Route path={`${match.url}/products`} component={ProductsRoutes} />
          <Route path={`${match.url}/brands`} component={BrandsRoutes} />
          <Route path={`${match.url}/bookmakers`} component={BookmakersRoutes} />
          <Route path={`${match.url}/main-bookmaker-mappings`} component={MainBookmakerMappingsRoutes} />
          <Route path={`${match.url}/surveys`} component={SurveysRoutes} />
          <Route path={`${match.url}/lineup-surveys`} component={LineupSurveysRoutes} />
          <Route path={`${match.url}/partners`} component={PartnersRoutes} />
          <Route path={`${match.url}/partner-offers`} component={PartnerOffersRoutes} />
          <Route path={`${match.url}/users`} component={UsersRoutes} />
          <Route path={`${match.url}/documentation`} component={DocumentationPages} />
          <Route path={`${match.url}/quizzes`} component={QuizzesRoutes} />
          <Route path={`${match.url}/mercatosurveys`} component={MercatoSurveysRoutes} />
        </Switch>
      </MainLayout>
    );
  }
}
