import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

@injectIntl
export default class ConfirmDeletionDialog extends Component {
  render() {
    const { open, onClose, onConfirm, deleteStatus, onBackToList, intl } = this.props;

    if (deleteStatus === 'success') {
      return (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'dialog_confirmTitle' })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: 'message_mainBookmakerMappingDeleted' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {deleteStatus ? (
              <>
                <Button onClick={onClose} color="primary">
                  {intl.formatMessage({ id: 'button_close' })}
                </Button>
                <Button onClick={onBackToList} color="primary" autoFocus>
                  {intl.formatMessage({ id: 'button_returnList' })}
                </Button>
              </>
            ) : (
              <>
                <Button onClick={onClose} color="primary">
                  {intl.formatMessage({ id: 'button_cancel' })}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                  {intl.formatMessage({ id: 'button_confirm' })}
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'dialog_confirmTitle' })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {deleteStatus === 'error'
                ? intl.formatMessage({ id: 'dialog_deleteError' })
                : intl.formatMessage({ id: 'dialog_deleteMessage' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {deleteStatus ? (
              <>
                <Button onClick={onClose} color="primary">
                  {intl.formatMessage({ id: 'button_close' })}
                </Button>
                <Button onClick={onBackToList} color="primary" autoFocus>
                  {intl.formatMessage({ id: 'button_backToList' })}
                </Button>
              </>
            ) : (
              <>
                <Button onClick={onClose} color="primary">
                  {intl.formatMessage({ id: 'button_cancel' })}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                  {intl.formatMessage({ id: 'button_confirm' })}
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      );
    }
  }
}
