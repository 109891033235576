import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import {
  fetchMappingsByCompetition,
  fetchMappingsByMatch,
  fetchMappingsByTeam,
  fetchMappingsByTeamInCompetition,
} from '../actions';

type IOwnProps = RouteComponentProps;

const mapDispatchToProps = {
  fetchMappingsByMatch,
  fetchMappingsByTeamInCompetition,
  fetchMappingsByTeam,
  fetchMappingsByCompetition,
};

const mapStateToProps = (state: IRootState) => {
  return {
    mainBookmakerMappingsByMatch: state.mainBookmakerMappingsByMatch.list,
    mainBookmakerMappingsByTeamInCompetition: state.mainBookmakerMappingsByTeamInCompetition.list,
    mainBookmakerMappingsByTeam: state.mainBookmakerMappingsByTeam.list,
    mainBookmakerMappingsByCompetition: state.mainBookmakerMappingsByCompetition.list,
    fetchingMainBookmakerMappingsByMatch: state.mainBookmakerMappingsByMatch.fetching,
    fetchingMainBookmakerMappingsByTeamInCompetition: state.mainBookmakerMappingsByTeamInCompetition.fetching,
    fetchingMainBookmakerMappingsByTeam: state.mainBookmakerMappingsByTeam.fetching,
    fetchingMainBookmakerMappingsByCompetition: state.mainBookmakerMappingsByCompetition.fetching,
    pagination: {
      match: state.mainBookmakerMappingsByMatch.pagination,
      teamInCompetition: state.mainBookmakerMappingsByTeamInCompetition.pagination,
      team: state.mainBookmakerMappingsByTeam.pagination,
      competition: state.mainBookmakerMappingsByCompetition.pagination,
    },
    total: {
      match: state.mainBookmakerMappingsByMatch.totalItems,
      teamInCompetition: state.mainBookmakerMappingsByTeamInCompetition.totalItems,
      team: state.mainBookmakerMappingsByTeam.totalItems,
      competition: state.mainBookmakerMappingsByCompetition.totalItems,
    },
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps>(reduxConnector, injectIntl);
