import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { compose } from "recompose";
import { IRootState } from '../../../typings';
import { fetchMainBookmakerMapping, deleteMainBookmakerMapping } from '../actions';

type IOwnProps = RouteComponentProps<{ id: string }>;

const mapDispatchToProps = {
  fetchMainBookmakerMapping,
  deleteMainBookmakerMapping,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const id = ownProps.match.params.id;

  return {
    id,
    mapping: state.mainBookmakerMapping.detail[id],
    fetching: state.mainBookmakerMapping.fetching,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
