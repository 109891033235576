import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../typings';
import { fetchAllBookmakers } from '../../actions';

const mapDispatchToProps = {
    fetchAllBookmakers,
};

const mapStateToProps = (state: IRootState) => ({
  bookmakers: state.bookmakerList.list || [],
  loading: state.bookmakerList.fetching,
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  injectIntl
);
