import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { injectIntl } from 'react-intl';
import ConfirmDeletionDialog from './ConfirmDeletionDialog';

@injectIntl
export default class DeleteStatusButton extends Component {
  state = {
    open: false,
    deleteStatus: null
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, deleteStatus: null });
  };

  handleDelete = async () => {
    try {
      await this.props.onRemove();
      this.setState({ deleteStatus: 'success' });
    } catch (error) {
      this.setState({ deleteStatus: 'error' });
    }
  };

  render() {
    const { className, disabled, intl, onBackToList } = this.props;
    const { open, deleteStatus } = this.state;

    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          className={className}
          style={{ marginRight: 8 }}
          disabled={disabled}
          onClick={this.handleClickOpen}
        >
          <DeleteIcon style={{ marginRight: 8 }} />
          {intl.formatMessage({ id: 'button_delete' })}
        </Button>
        <ConfirmDeletionDialog
          open={open}
          onClose={this.handleClose}
          onConfirm={this.handleDelete}
          deleteStatus={deleteStatus}
          onBackToList={onBackToList}
          intl={intl}
        />
      </>
    );
  }
}
