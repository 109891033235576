import rest from 'Services/restFootData';
import qs from "query-string";

export function fetchMainBookmakerMapping(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPING' });

    return rest
        .get(`main_bookmaker_mappings/${id}`)
        .then(
            res => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPING_FULFILLED', payload: res.data }),
            err => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPING_REJECTED', payload: err.response })
        );
  };
}

export function fetchMappingsByMatch(search) {
  const params = {
    'exists[match]': 1,
    'order[createdAt]': 'desc',
    groups: ['read_link'],
    itemsPerPage: 10,
    partial: 0,
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_MATCH' });

    return rest
      .get('main_bookmaker_mappings', { params })
      .then(
        res => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_MATCH_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_MATCH_REJECTED', payload: err.response })
      );
  };
}

export function fetchMappingsByTeamInCompetition(search) {
  const params = {
    ...qs.parse(search),
    'exists[team]': 1,
    'exists[competition]': 1,
    'order[createdAt]': 'desc',
    groups: ['read_link'],
    itemsPerPage: 10,
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_TEAM_IN_COMPETITION' });

    return rest
      .get('main_bookmaker_mappings', { params })
      .then(
        res => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_TEAM_IN_COMPETITION_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_TEAM_IN_COMPETITION_REJECTED', payload: err.response })
      );
  };
}

export function fetchMappingsByTeam(search) {
  const params = {
    ...qs.parse(search),
    'exists[team]': 1,
    'exists[competition]': 0,
    'order[createdAt]': 'desc',
    groups: ['read_link'],
    itemsPerPage: 10,
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_TEAM' });

    return rest
      .get('main_bookmaker_mappings', { params })
      .then(
        res => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_TEAM_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_TEAM_REJECTED', payload: err.response })
      );
  };
}

export function fetchMappingsByCompetition(search) {
  const params = {
    ...qs.parse(search),
    'exists[competition]': 1,
    'exists[team]': 0,
    'order[createdAt]': 'desc',
    groups: ['read_link'],
    itemsPerPage: 10,
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_COMPETITION' });

    return rest
      .get('main_bookmaker_mappings', { params })
      .then(
        res => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_COMPETITION_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MAIN_BOOKMAKER_MAPPINGS_BY_COMPETITION_REJECTED', payload: err.response })
      );
  };
}

export function deleteMainBookmakerMapping(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_MAIN_BOOKMAKER_MAPPING' });

    return rest
      .delete(`main_bookmaker_mappings/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_MAIN_BOOKMAKER_MAPPING_FULFILLED', payload: id }),
        err => dispatch({ type: 'DELETE_MAIN_BOOKMAKER_MAPPING_REJECTED', payload: err.response })
      );
  };
}

export function addMainBookmakerMapping(mapping) {
  return function(dispatch) {
    dispatch({ type: 'ADD_MAIN_BOOKMAKER_MAPPING' });

    return rest.post('main_bookmaker_mappings', mapping).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: 'message_mainBookmakerMappingAdded', type: 'success' },
        });
        return dispatch({ type: 'ADD_MAIN_BOOKMAKER_MAPPING_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'ADD_MAIN_BOOKMAKER_MAPPING_REJECTED', payload: err.response })
    );
  };
}
