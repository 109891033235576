import React from 'react';
import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import Layout from '../../../common/components/Layout';
import ReturnButton from "../../../common/components/ReturnButton";
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from "../../../common/components/TabsRoute";
import { getProject } from "../../../common/services/project";
import { IComponentProps } from './MainBookmakerMappingDetail.connector';
import { Route } from "react-router-dom";
import { MainBookmakerMappingDetailView } from "../components/MainBookmakerMappingDetailView";

export class MainBookmakerMappingDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_info', link: '/' }];

  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    const {
      mapping,
      fetching,
      match,
      history,
      intl: { messages: m }
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }

    if (!mapping) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="main-bookmaker-mapping-detail-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_mainBookmakerMapping', href: `#${getProject().url}/main-bookmaker-mappings` },
              { label: mapping.id }
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{mapping.id}</Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="main-bookmaker-mappings" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderDetailView} />
          </Paper>
        }
      />
    );
  }

  private handleDelete = (id: string) => {
    this.props.deleteMainBookmakerMapping(id);
  };

  private handleBackToList = () => {
    this.props.history.push(`${getProject().url}/main-bookmaker-mappings`);
  };

  private renderDetailView = () => {
    const { mapping } = this.props;
    // @ts-ignore
    return <MainBookmakerMappingDetailView data={mapping} onDelete={this.handleDelete} onBackToList={this.handleBackToList}/>;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchMainBookmakerMapping } = this.props;
    fetchMainBookmakerMapping(targetId || id);
  };
}
