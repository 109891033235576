import { TableCell, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { getProject } from "../../../common/services/project";
import { IMainBookmakerMapping } from '../typings';

interface IProps {
  tableData: IMainBookmakerMapping;
  context: 'match' | 'teamInCompetition' | 'team' | 'competition';
}

export class MainBookmakerMappingRow extends Component<IProps> {
  public render() {
    const { tableData, context } = this.props;
    const href = `#${getProject().url}/main-bookmaker-mappings/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>{tableData.bookmaker.name}</TableCell>
        {(context === 'teamInCompetition' || context === 'team') && <TableCell>{tableData.team?.name || '-'}</TableCell>}
        {(context === 'teamInCompetition' || context === 'competition') && (
          <TableCell>
            {tableData.competition?.name || '-'}<br />
            {tableData.competition?.area?.name || '-'}
          </TableCell>
        )}
        {context === 'match' && (
          <TableCell>
            {tableData.match?.description || '-'}<br />
            {tableData.match?.date ? new Date(tableData.match.date).toLocaleString() : '-'}<br />
            {tableData.match?.phaseTournamentCompetitionName || '-'}
          </TableCell>
        )}
        <TableCell>
          {new Date(tableData.createdAt).toLocaleString()}
        </TableCell>
      </TableRow>
    );
  }
}
