import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { IBookmaker } from '../../typings';

interface IOwnProps {
  input: {
    name: string;
    value: string;
    onChange: (value: unknown) => void;
  };
  meta?: {
    touched?: boolean;
    error?: string;
  };
  labelKey?: string;
  className?: string;
  nullable?: boolean;
  disabled?: boolean;
  bookmakers: IBookmaker[];
  loading: boolean;
  fetchAllBookmakers: () => void;
}

type IProps = IOwnProps & InjectedIntlProps;

export class BookmakerSelect extends React.Component<IProps> {
  public componentDidMount() {
    this.props.fetchAllBookmakers();
  }

  public render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error } = {},
      intl: { formatMessage: f },
      labelKey,
      bookmakers,
      className,
      nullable,
      disabled,
      loading,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <FormControl
        fullWidth
        error={Boolean(touched && error)}
        className={className}
        disabled={disabled || loading}
      >
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        <Select
          name={name}
          value={value || ''}
          onChange={event => onChange(event.target.value === '' ? null : event.target.value)}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          {nullable && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {bookmakers.map(bookmaker => (
            <MenuItem key={bookmaker.id} value={bookmaker['@id']}>
              {bookmaker.name}
            </MenuItem>
          ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
