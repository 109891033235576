import { agentCompaniesReducer, agentCompanyReducer } from './agentCompanies/reducers';
import { broadcastReducer, broadcastsReducer } from './broadcasts/reducers';
import channel from './channels/reducers/detail';
import channels from './channels/reducers/list';
import classement from './classements/reducers/detail';
import club from './clubs/reducers/detail';
import clubs from './clubs/reducers/list';
import clubSponsor from './clubSponsors/reducers/detail';
import commentary from './commentaries/reducers/detail';
import competition from './competitions/reducers/detail';
import competitions from './competitions/reducers/list';
import ageGroups from './components/ageGroups/reducers';
import areas from './components/areas/reducers';
import bookmakers from './components/bookmakers/reducers';
import breadcrumb from './components/Breadcrumb/reducer';
import constants from './components/constants/reducers';
import organizations from './components/organizations/reducers';
import seasons from './components/seasons/reducers';
import teamSeason from './components/teamSeasons/reducers';
import contract from './contracts/reducers/detail';
import correction from './corrections/reducers/detail';
import corrections from './corrections/reducers/list';
import counters from './dashboard/reducers';
import image from './images/reducers/detail';
import images from './images/reducers/list';
import { liveReducer } from './live/Commentary/reducer';
import { liveCompositionReducer } from './live/Composition/reducer';
import match from './matches/reducers/detail';
import matches from './matches/reducers/list';
import bookmaker from './bookmakers/reducers/detail';
import bookmakerList from './bookmakers/reducers/list';
import mainBookmakerMapping from './mainBookmakerMappings/reducers/detail';
import mainBookmakerMappingsByMatch from './mainBookmakerMappings/reducers/mainBookmakerMappingsByMatch';
import mainBookmakerMappingsByTeamInCompetition from './mainBookmakerMappings/reducers/mainBookmakerMappingsByTeamInCompetition';
import mainBookmakerMappingsByTeam from './mainBookmakerMappings/reducers/mainBookmakerMappingsByTeam';
import mainBookmakerMappingsByCompetition from './mainBookmakerMappings/reducers/mainBookmakerMappingsByCompetition';
import {
  cardReducer,
  goalReducer,
  matchEventReducer,
  missedPenaltyReducer,
  penaltyShotReducer,
  substituteReducer,
} from './matchEvents/reducers';
import { matchTeamReducer } from './matchTeams/reducers';
import membership from './memberships/reducers/detail';
import mercatoSurvey from './mercatoSurveys/reducers/detail';
import mercatoSurveyList from './mercatoSurveys/reducers/list';
import agent from './people/agents/reducers/detail';
import agents from './people/agents/reducers/list';
import coach from './people/coaches/reducers/detail';
import coaches from './people/coaches/reducers/list';
import injury from './injuries/reducers/detail';
import injuryTypesField from './components/injuryTypes/reducers';
import injuryTypes from './injuryTypes/reducers/list';
import player from './people/players/reducers/detail';
import players from './people/players/reducers/list';
import president from './people/presidents/reducers/detail';
import presidents from './people/presidents/reducers/list';
import person from './people/reducers/detail';
import people from './people/reducers/list';
import referee from './people/referees/reducers/detail';
import referees from './people/referees/reducers/list';
import staff from './people/staffs/reducers/detail';
import staffs from './people/staffs/reducers/list';
import personSponsor from './personSponsors/reducers/detail';
import phase from './phases/reducers/detail';
import product from './products/reducers/detail';
import products from './products/reducers/list';
import brand from './brands/reducers/detail';
import brands from './brands/reducers/list';
import searchFD from './search/reducers';
import stats from './stats/reducers';
import team from './teams/reducers/detail';
import teams from './teams/reducers/list';
import teamSeasonMember from './teamSeasonMembers/reducers/detail';
import rankingRule from './tournaments/rankingRules/reducers/detail';
import tournament from './tournaments/reducers/detail';
import tournaments from './tournaments/reducers/list';
import transfer from './transfers/reducers/detail';
import transfers from './transfers/reducers/list';
import venue from './venues/reducers/detail';
import survey from './surveys/reducers/detail';
import surveyList from './surveys/reducers/list';
import quizzList from './quizz/reducers/list';
import quizz from './quizz/reducers/details';
import partner from './partners/reducers/detail';
import partnerList from './partners/reducers/list';
import partnerOffer from './partners/reducers/detailPartnerOffer';
import partnerOfferList from './partners/reducers/listPartnerOffers';
import partnerOfferByPartnerList from "./partners/reducers/listPartnerOffersByPartner";
import lineupSurvey from "./lineupSurveys/reducers/detail";
import lineupSurveyList from "./lineupSurveys/reducers/list";

export default {
  ageGroups,
  agent,
  agentCompanies: agentCompaniesReducer,
  agentCompany: agentCompanyReducer,
  agents,
  areas,
  bookmaker,
  bookmakers,
  bookmakerList,
  breadcrumb,
  broadcast: broadcastReducer,
  broadcasts: broadcastsReducer,
  channel,
  channels,
  classement,
  club,
  clubs,
  clubSponsor,
  coach,
  coaches,
  commentary,
  competition,
  competitions,
  constants,
  contract,
  correction,
  corrections,
  counters,
  injury,
  injuryTypes,
  injuryTypesField,
  image,
  images,
  live: liveReducer,
  liveComposition: liveCompositionReducer,
  match,
  matches,
  matchEvent: matchEventReducer,
  card: cardReducer,
  goal: goalReducer,
  substitute: substituteReducer,
  missedPenalty: missedPenaltyReducer,
  penaltyShot: penaltyShotReducer,
  matchTeam: matchTeamReducer,
  membership,
  mercatoSurvey,
  mercatoSurveyList,
  organizations,
  partner,
  partnerList,
  partnerOffer,
  partnerOfferByPartnerList,
  partnerOfferList,
  people,
  person,
  personSponsor,
  phase,
  player,
  players,
  president,
  presidents,
  product,
  products,
  quizzList,
  quizz,
  brand,
  brands,
  rankingRule,
  referee,
  referees,
  searchFD,
  seasons,
  survey,
  surveyList,
  staff,
  staffs,
  stats,
  team,
  teams,
  teamSeason,
  teamSeasonMember,
  tournament,
  tournaments,
  transfer,
  transfers,
  venue,
  lineupSurvey,
  lineupSurveyList,
  mainBookmakerMapping,
  mainBookmakerMappingsByMatch,
  mainBookmakerMappingsByTeamInCompetition,
  mainBookmakerMappingsByTeam,
  mainBookmakerMappingsByCompetition,
};
