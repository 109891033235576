import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { BookmakerForm } from '../components/BookmakerForm';
import { IBookmaker } from '../typings';
import { IComponentProps } from './BookmakerDetail.connector';

export class BookmakerDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_info', link: '/' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      bookmaker,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!bookmaker) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="bookmaker-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_bookmakers', href: `#${getProject().url}/bookmakers` },
              { label: bookmaker.name }
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{bookmaker.name}</Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="bookmakers" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { bookmaker } = this.props;
    return <BookmakerForm onSubmit={this.handleUpdate} data={bookmaker} />;
  };

  private handleUpdate = (bookmaker: IBookmaker) => {
    const { id, updateBookmaker } = this.props;
    return updateBookmaker(id, bookmaker);
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchBookmaker } = this.props;
    fetchBookmaker(targetId || id);
  };
}
