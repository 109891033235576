import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AddMainBookmakerMapping } from "./Add";
import { MainBookmakerMappingDetail } from './Detail';
import { MainBookmakerMappingList } from './List';

export const MainBookmakerMappingsRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={MainBookmakerMappingList} />
    <Route path={`${match.url}/add`} component={AddMainBookmakerMapping} />
    <Route path={`${match.url}/:id`} component={MainBookmakerMappingDetail} />
  </Switch>
);
