import { Grid, Paper, Typography } from '@material-ui/core';
import { Component } from 'react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import DeleteStatusButton from "../../../../common/components/DeleteStatusButton";
import { IMainBookmakerMapping } from '../../typings';

interface IOwnProps {
  data: IMainBookmakerMapping;
  onDelete: (id: string) => Promise<void>;
  onBackToList: () => void;
}

type IProps = IOwnProps & InjectedIntlProps;

// @ts-ignore
@injectIntl
export class MainBookmakerMappingDetailView extends Component<IProps> {

  public render() {
    const { data, intl, onBackToList } = this.props;

    return (
      <Paper elevation={2} style={{ padding: 16 }}>
        <Typography variant="h6" gutterBottom>
          {intl.formatMessage({ id: 'app_mainBookmakerMapping' })}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">{intl.formatMessage({ id: 'field_bookmaker' })}:</Typography>
            <Typography variant="body1">{data.bookmaker.name}</Typography>
          </Grid>
          {data.competition && (
            <Grid item xs={6}>
              <Typography variant="subtitle1">{intl.formatMessage({ id: 'field_competition' })}:</Typography>
              <Typography variant="body1">{data.competition.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.competition.area?.name || ''}
              </Typography>
            </Grid>
          )}
          {data.team && (
            <Grid item xs={6}>
              <Typography variant="subtitle1">{intl.formatMessage({ id: 'field_team' })}:</Typography>
              <Typography variant="body1">{data.team.name}</Typography>
            </Grid>
          )}
          {data.match && (
            <Grid item xs={6}>
              <Typography variant="subtitle1">{intl.formatMessage({ id: 'field_match' })}:</Typography>
              <Typography variant="body1">{data.match.description}</Typography>
              <Typography variant="body2" color="textSecondary">
                {new Date(data.match.date).toLocaleString()}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {data.match.phaseTournamentCompetitionName}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography variant="subtitle1">{intl.formatMessage({ id: 'field_createdAt' })}:</Typography>
            <Typography variant="body1">{new Date(data.createdAt).toLocaleString()}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">{intl.formatMessage({ id: 'field_updatedAt' })}:</Typography>
            <Typography variant="body1">{new Date(data.updatedAt).toLocaleString()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <DeleteStatusButton onRemove={this.handleDelete} onBackToList={onBackToList} />
          </Grid>
        </Grid>
      </Paper>
    );
  }
  private handleDelete = () => {
    return this.props.onDelete(this.props.data.id);
  };
}
